.testimonials {
    min-height: 60vh;
    width: 100%;
    background-color: var(--blue);
}

.testimonials>.title {
    padding-top: 50px;
    font-family: var(--main);
    font-size: 64px;
    color: var(--jet);
    padding-left: 4%;
}

.testimonials>.sub-title {
    font-family: var(--text);
    font-size: 18px;
    color: var(--jet);
    padding-left: 5%;
}

.container {
    width: 100vw;
    overflow: hidden;
    position: relative;
    padding-top: 2%;
    font-family: var(--text);
    color: var(--jet);

    .items {
        display: flex;
        align-items: center;
        width: fit-content;
        animation: carouselAnim 60s infinite alternate linear;

        .entry {
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            width: 300px;
            min-height: 200px;
            background: var(--white);
            margin: 1em;
            padding: .5em;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            border-radius: 10px;

            p {
                text-align: center;
                align-self: center;
                padding: 1em;
            }

            p.name {
                font-weight: bold;
            }

            p.quote {
                color: var(--gray);
            }

            @keyframes carouselAnim {
                from {
                    transform: translate(0, 0);
                }

                to {
                    transform: translate(calc(-100% + (5*300px)));
                }
            }


        }
    }

    @media only screen and (max-width: 768px) {
        .items {
            animation: carouselAnim 30s infinite alternate linear;
        }

        @keyframes carouselAnim {
            from {
                transform: translate(0, 0);
            }

            to {
                transform: translate(calc(-100% + (2*300px)));
            }
        }
    }
}

@media (max-width: 950px) {
    .testimonials>.title {
        text-align: center;
        font-size: 60px;
        padding-left: 0;
    }

    .testimonials>.sub-title {
        text-align: center;
        padding-left: 0;
    }
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
    .testimonials {
        min-height: 50vh;
    }

    .testimonials>.title {
        font-size: 50px;
    }
}