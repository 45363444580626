.about {
  min-height: 100vh;
}

.about > .title {
  padding-top: 100px;
  padding-left: 4%;
  font-family: var(--main);
  font-size: 64px;
  color: var(--jet);
}

.about > .about-container {
  display: grid;
  grid-template-columns: 10% 1fr 1fr 10%;
}

.about > .about-container > .text {
  grid-column: 1/3;
  padding-left: 8%;
  padding-top: 5%;
  font-family: var(--text);
  font-size: 16px;
  line-height: 2;
  color: var(--gray);
}

.studio-btn {
  margin-top: 20px;
  margin-bottom: 25px;
  border: 1px var(--black) solid;
  color: var(--black);
  font-size: 14px;
  padding: 8px 25px;
  background-color: transparent;
  text-transform: capitalize;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.studio-btn:hover {
  background-color: var(--jet);
  color: var(--white);
}

.about > .about-container > .image-section {
  padding-left: 40%;
  grid-column: 3/4;
  justify-self: center;
}

.about > .about-container > .image-section > img {
  height: 500px;
  width: 350px;
  object-fit: cover;
  justify-self: center;
}

@media (max-width: 1050px) {
  .about > .about-container > .text {
    font-size: 14px;
  }
}

@media (max-width: 950px) {
  .about > .title {
    padding-left: 0;
    padding-top: 8%;
    padding-bottom: 0;
    font-size: 60px;
    text-align: center;
  }

  .about > .about-container > .image-section {
    margin-bottom: 50px;
  }

  .about > .about-container > .image-section > img {
    height: 500px;
    width: 400px;
  }

  .about > .about-container {
    display: flex;
    flex-direction: column;
  }

  .about > .about-container > .image-section {
    text-align: center;
    padding: 0;
  }

  .about > .about-container > .text {
    align-self: center;
    padding-left: 0;
    width: 90vw;
    margin-bottom: 50px; 
  }

  .studio-btn {
    align-self: center;
    text-align: center;
  }
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  .about > .title {
    padding-top: 100px;
    font-size: 50px;
  }
  .about > .about-container > .image-section > img {
    width: 90vw;
    height: 450px
  }

  .about > .about-container > .text {
    width: 90vw;
    font-size: 16px;
  }

  .studio-btn {
    margin-left: 32%;
  }
}
