.nav {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  height: 80px;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: var(--text);
}

.focused-nav {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  height: 80px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--text);
  border-bottom: 1px var(--black) solid;
}

.nav.active {
  border-bottom: 1px var(--black) solid;
}

.nav > .nav-header {
  align-self: center;
  text-decoration: none;
}

.nav > .nav-header > .nav-title > a {
  font-size: 26px;
  font-family: var(--main);
  color: var(--black);
  list-style-type: none;
  text-decoration: none ;
  cursor: pointer;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  font-size: 15px;
  align-self: center;
}

.nav > .nav-links > a {
  display: inline-block;
  padding: 20px;
  text-decoration: none;
  color: var(--black);
  letter-spacing: 2px;
  text-transform: capitalize;
}

.nav > .nav-links > .studio-link > a {
  display: inline-block;
  text-decoration: none;
  color: var(--black);
  letter-spacing: 2px;
  text-transform: capitalize;
  cursor: pointer;
}

#home-link-mobile {
  display: none;
}

#contact-link-mobile {
  display: none;
}

.contact-section {
  align-self: center;
}

.contact-section > a > .contact-btn {
  border: 1px var(--black) solid;
  color: var(--black);
  font-size: 14px;
  padding: 8px 25px; 
  background-color: transparent;
  text-transform: capitalize;
  letter-spacing: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.contact-section > a > .contact-btn:hover {
  background-color: var(--jet);
  color: var(--white);
}

.nav > #nav-check {
  display: none;
}

.back-btn {
  background-color: var(--white);
  width: 80px;
  border: none;
  color: var(--black);
  cursor: pointer;
}

.focused-nav > .contact-section {
  margin-right: 12%;
}

@media (max-width: 950px) {
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 5%;
    top: 20px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 3px solid  var(--jet);
  }
  
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: var(--white);
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    font-size: 40px;
    top: 80px;
    left: 0px;
  }

  #home-link-mobile {
    display: block;
  }
  
  #contact-link-mobile {
    display: block;
  }
  
  .nav > .nav-header > .nav-title {
    display: none;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;

  }

  .contact-section {
    display: none;
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}
