@import 'navbar';
@import 'landing';
@import 'about';
@import 'work';
@import 'services';
@import 'studio';
@import 'testimonials';
@import 'contact';
@import 'footer';

/* font imports */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

:root {
    /* color variables */
    --black: #333;
    --white: #fff;
    --blue: #e9f2f7;
    --jet: #363635;
    --gray: #6e7682;
    // #6e7682;
    
    /* font variables */
    --main: 'DM Serif Display', serif;
    --text: 'Helvetica Neue', Arial, sans-serif;
}

* {
    margin: 0; 
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    margin: 0;
    background-color: white;
}

.app {
    min-height: 100vh;
}
