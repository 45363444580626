.landing {
  min-height: 80vh;
  display: flex;
  justify-content: center;
}

.landing > div > .background-image {
  width: 90vw;
  height: 80vh;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  border-radius: 10px;
  filter: brightness(90%);
}

.landing > .text-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.landing > .text-section > div > .title {
  color: var(--white);
  font-family: var(--main);
  font-size: 100px;
}

.landing > .text-section > div > .sub-title {
  color: var(--white);
  font-family: var(--text);
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}

.landing > .text-section > div > a > .see-services {
  border: 2px var(--white) solid;
  padding: 10px 25px;
  background: transparent;
  text-transform: capitalize;
  color: var(--white);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 5px;
  margin-top: 5%;
  cursor: pointer;
}

@media (max-width: 1275px) {
  .landing > .text-section > div > .title {
    font-size: 85px;
  }
  
}

@media (max-width: 1080px) {
  .landing > .text-section > div > .title {
    font-size: 75px;
  }

  .landing > .text-section > div > .sub-title {
    font-size: 16px;
  }
}

@media (max-width: 975px) {
  .landing > .text-section > div > .title {
    font-size: 65px;
  }

  .landing > .text-section > div > .sub-title {
    font-size: 14px;
  }

  .landing > .text-section > div > a > .see-services {
    font-size: 14px;
  }
}

@media (max-width: 825px) {
  .landing > .text-section > div > .title {
    font-size: 100px;
  }
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  .landing > .text-section > div > .title {
    font-size: 80px;
  }

  .landing > .text-section > div > .sub-title {
    display: none;
  }
}
