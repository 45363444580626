.studio {
  min-height: 100vh;
  width: 100%;

  .landing-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 5%;

    .title-section {
      font-family: var(--text);
      color: var(--jet);
      padding-left: 5%;
      text-align: center;

      .title,
      .sub-title {
        font-size: 80px;
        font-weight: 500;
        color: var(--jet);
      }

      .studio-name {
        font-size: 40px;
        padding-top: 5%;
        font-family: var(--main);
      }
    }

    .image-section {
      padding-right: 5%;

      .studio-landing-img {
        width: 45vw;
      }
    }
  }

  .middle-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 2%;
    background-color: var(--blue);

    .text-section {
      width: 50vw;
      padding-right: 8%;
      padding-left: 2%;
      line-height: 2;
      font-size: 18px;
      font-family: var(--text);
      color: var(--jet);

      .contact-btn {
        margin-bottom: 5%;
        color: var(--jet);
        font-size: 18px;
        padding: 8px 25px;
        background-color: transparent;
        text-transform: capitalize;
        letter-spacing: 2px;
        border-radius: 5px;
        cursor: pointer;
        font-family: var(--text);
        border: 1px solid var(--jet);
      }

      .contact-btn:hover {
        background-color: var(--jet);
        color: var(--white)
      }
    }

    .image-section {
      padding-left: 8%;

      .studio-img {
        width: 400px;
      }
    }
  }

  .last-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 10%;

    .image-section {
      padding-right: 5%;

      .studio-img {
        align-self: center;
        width: 40vw;
      }
    }

    .text-section {
      padding-left: 8%;
      width: 50vw;
      line-height: 2;
      font-size: 22px;
      font-family: var(--text);
      color: var(--jet);
    }
  }
}

$width: 40vw;
$height: 80vh;
$numberOfItems: 4;
$visibleItemDuration: 4s;
$transitionDuration: 1s;

$oneItemDuration: $visibleItemDuration + $transitionDuration;
$carouselPeriod: $oneItemDuration * $numberOfItems;

.dissolve {
  width: $width;
  height: $height;
  position: relative;
  overflow: hidden;

  .studio-img {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    animation: dissolve $carouselPeriod linear infinite;
    object-fit: cover;

    &:nth-child(2) {
      animation-delay: $oneItemDuration;
    }

    &:nth-child(3) {
      animation-delay: 2 * $oneItemDuration;
    }

    &:nth-child(4) {
      animation-delay: 3 * $oneItemDuration;
    }
  }
}

@-webkit-keyframes dissolve {

  0%,
  30%,
  100% {
    opacity: 0;
  }

  5%,
  25% {
    opacity: 1;
  }
}

@-moz-keyframes dissolve {

  0%,
  30%,
  100% {
    opacity: 0;
  }

  5%,
  25% {
    opacity: 1;
  }
}

@-ms-keyframes dissolve {

  0%,
  30%,
  100% {
    opacity: 0;
  }

  5%,
  25% {
    opacity: 1;
  }
}

@keyframes dissolve {

  0%,
  30%,
  100% {
    opacity: 0;
  }

  5%,
  25% {
    opacity: 1;
  }
}

figure {
  width: $width;

  .carousel {
    width: $width;
  }
}

@media (max-width: 1250px) {

  .studio {
    .landing-section {
      text-align: center;
      justify-content: center;

      .title-section {
        padding-left: 2%;
        padding-right: 2%;

        .title,
        .sub-title {
          font-size: 65px;
        }

        .studio-name {
          font-size: 30px;
        }

      }
    }
  }

  .title,
  .sub-title {
    font-size: 60px;
  }

  .studio-name {
    font-size: 30px;
  }

  .text-section {
    padding-left: 0;
  }
}

@media (max-width: 1000px) {
  .studio {
    .landing-section {
      .title-section {
        padding-left: 0;
        padding-right: 0;

        .title,
        .sub-title {
          font-size: 80px;
        }

        .studio-name {
          font-size: 40px;
          padding-top: 5%;
        }
      }

      .image-section {
        display: none;
        padding-right: 0;
      }
    }

    .middle-section {
      flex-direction: column-reverse;
      padding-top: 5%;
      padding-bottom: 5%;
      background-color: var(--white);

      .text-section {
        display: flex;
        flex-direction: column;
        width: 85vw;
        padding-right: 0;
        padding-left: 0;
        font-size: 18px;
        padding-bottom: 25px;

        .contact-btn {
          align-self: center;
          align-items: center;
          margin-right: 40%;
          margin-left: 40%;
        }
      }

      .image-section {
        padding-left: 0;

        .studio-img {
          width: 60vw;
        }
      }
    }

    .last-section {
      flex-direction: column;
      padding-top: 1%;
      padding-bottom: 10%;
      text-align: center;

      .image-section {
        padding-right: 0;

        .studio-img {
          width: 60vw;
        }
      }

      .text-section {
        padding-left: 0;
        padding-bottom: 5%;
        width: 90vw;
        font-size: 18px;
      }
    }
  }

  figure {
    width: 60vw;

    .carousel {
      width: 60vw;
    }
  }
}

@media (max-width: 650px) {
  .dissolve {
    height: 60vh;
  }
}

@media (max-width: 575px) {

  .studio {
    .landing-section {
      .title-section {

        .title,
        .sub-title {
          font-size: 60px;
        }

        .studio-name {
          font-size: 30px;
        }
      }
    }

    .middle-section {
      .text-section {
        text-align: center;
        width: 90vw;
      }
    }

    .last-section {
      .image-section {
        .studio-img {
          width: 90vw;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  .studio {
    overflow-x: hidden;
    .landing-section {
      .title-section {

        .title,
        .sub-title {
          font-size: 50px;
        }

        .studio-name {
          font-size: 30px;
        }
      }
    }

    .middle-section {
      .text-section {
        font-size: 16px;
        color: var(--gray);

        .contact-btn {
          margin-left: 33%;
        }
      }

      .image-section {
        .studio-img {
          width: 85vw;
        }
      }
    }

    .last-section {
      .text-section {
        font-size: 16px;
        color: var(--gray);
        width: 90vw;
      }
    }
  }

  figure {
    width: 85vw;

    .carousel {
      width: 85vw;
    }
  }

  .dissolve {
    height: 65vh;
  }
}