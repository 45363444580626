.contact-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: var(--white);
  font-family: var(--text);
}

.contact-information {
  width: 90vh;
  font-family: var(--text);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.contact-header {
  background-color: var(--jet);
  color: var(--white);
  padding: 20px;
  padding-left: 5%;
}

.contact-body {
  background-color: white;
  padding: 20px;
  padding-left: 5%;
  color: var(--jet)
}

.contact-info-message {
  font-family: var(--main);
  font-size: 32px;
}

.contact-info-text {
  font-size: 20px;
}

.contact-info-option {
  font-size: 18px;
  padding-top: 40px;
}

.contact-info-option > a  {
  color: var(--jet);
  text-decoration: none;
}

.contact-info-option.phone {
  letter-spacing: 1px;
}

@media (max-width: 720px) {
  .contact-information {
    width: 95vw;
  }
  .contact-info-text {
    font-size: 16px;
  }
}

