.services {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.services>.title {
  padding-top: 100px;
  padding-left: 4%;
  padding-bottom: 3%;
  font-family: var(--main);
  font-size: 64px;
  color: var(--jet);
}

.services>.service-container {
  width: 90vw;
  padding-top: 2%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 25px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding-left: 3%;
  padding-right: 3%;
}

.services>.service-container>.service-category {
  margin-bottom: 25px;
  padding: 20px;
  border-bottom: 1px solid rgb(171, 170, 170);
}

.services>.service-container>.service-category.facials {
  border: none;
}

.services>.service-container>.service-category>.multi-col-services {
  display: flex;
  justify-content: space-between;
  font-family: var(--text);
  color: var(--gray);
  line-height: 2;
}

.services>.service-container>.service-category>.title {
  font-family: var(--main);
  color: var(--black);
  font-size: 24px;
}

.services>.service-container>.service-category>.multi-col-services>ul>.subtitle {
  color: var(--jet);
  font-family: var(--main);
  font-size: 20px;
}

.services>.service-container>.service-category>ul {
  font-family: var(--text);
  color: var(--gray);
  line-height: 2;
}

li {
  list-style-type: circle;
}

.note {
  font-family: var(--text);
  font-size: 14px;
  color: var(--gray);
}

#other {
  margin-right: 70px;
}

@media (max-width: 950px) {
  .services>.title {
    padding-left: 0;
    text-align: center;
    font-size: 60px;
  }

  .services>.service-container>.service-category>.multi-col-services {
    flex-direction: column;
  }

  .services>.service-container {
    text-align: left;
    padding-left: 10px;
  }

  .services>.service-container>.service-category>.title {
    text-align: center;
  }

  li {
    list-style-type: circle;
  }

  .services>.service-container>.service-category>ul {
    line-height: 3;
  }

  .services>.service-container>.service-category>.multi-col-services>ul {
    line-height: 3;
  }

  .services>.service-container>.service-category>.title {
    font-size: 28px;
  }
}


@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  .services>.title {
    font-size: 50px;
  }

  .services>.service-container>.service-category>ul {
    font-size: 14px;
  }

  .services>.service-container>.service-category>.multi-col-services>ul {
    font-size: 14px;
  }

  .services>.service-container>.service-category>.title {
    text-align: center;
  }

  .note {
    font-size: 12px;
  }
}