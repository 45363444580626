.work {
    min-height: 100vh;
    background-color: var(--blue);
    width: 100%;

    .title {
        padding-top: 100px;
        padding-left: 4%;
        font-family: var(--main);
        font-size: 64px;
        color: var(--jet);
    }

    .work-container {
        text-align: center;

        .gallery-container {
            .work-card {
                padding-bottom: 0;
            }
        }

        .browse-all-btn {
            margin-bottom: 5%;
            color: var(--jet);
            font-size: 18px;
            padding: 8px 25px;
            background-color: transparent;
            text-transform: capitalize;
            letter-spacing: 2px;
            border-radius: 5px;
            cursor: pointer;
            font-family: var(--text);
            border: 1px solid var(--jet);
        }

        .browse-all-btn:hover {
            background-color: var(--jet);
            color: var(--white)
        }
    }
}

.all-work {
    width: 100%;
    min-height: 100vh;

    .gallery-container {
        flex-wrap: wrap
    }
}

.gallery-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding-top: 5%;

    .work-card {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 3%;

        .work-image {
            width: 350px;
            height: 350px;
            object-fit: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .content {
            width: 400px;
            height: 100px;
            color: var(--gray);
            font-family: var(--text);
            font-size: 14px;
            padding-top: 10px;
            letter-spacing: 2px;
            font-style: italic;
            text-align: center;
        }
    }
}

@media (max-width: 1200px) {
    .work {
        .work-container {
            .gallery-container {
                .work-card {
                    padding-left: 2px;
                    padding-right: 2px;
                    width: 300px;

                    .work-image {
                        width: 300px;
                        height: 300px;
                    }

                    .content {
                        width: 300px;
                    }
                }
            }
        }
    }

}


@media (max-width: 950px) {
    .work {
        .title {
            text-align: center;
            padding-left: 0;
            font-size: 60px;
        }

        .work-container {
            .gallery-container {
                flex-direction: column;

                .work-card {
                    padding-left: 0;
                    padding-right: 0;
                    width: 400px;

                    .work-image {
                        width: 400px;
                        height: 400px;
                    }

                    .content {
                        width: 400px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
    .work {
        .title {
            font-size: 50px;
        }

        .work-container {
            .gallery-container {
                flex-direction: column;

                .work-card {
                    padding-left: 0;
                    padding-right: 0;
                    width: 90vw;

                    .work-image {
                        width: 90vw;
                        height: 350px;
                    }

                    .content {
                        width: 90vw;
                    }
                }
            }
        }
    }


    .gallery-container {
        .work-card {
            padding-left: 0;
            padding-right: 0;
            width: 90vw;

            .work-image {
                width: 90vw;
                height: 350px;
            }

            .content {
                width: 90vw;
            }
        }
    }
}