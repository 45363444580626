.footer {
    display: grid;
    grid-template-columns: 10% 1fr 1fr 20%;
    align-items: center;
    min-height: 20vh;
    background-color: var(--jet);
  }
  
  .copyright {
    grid-column: 4;
    font-family: var(--text);
    font-size: 14px;
    color: var(--white);
  }
  
  .follow-me {
    font-family: var(--text);
    font-size: 22px;
    font-weight: bold;
    color: var(--white);
    margin-bottom: 1%;
  }
  
  .social-links {
    grid-column: 2;
    color: var(--white);
    cursor: pointer;
  }
  
  .social-link {
    color: var(--white);
    margin-left: 2%;
    cursor: pointer;
  }
  
  @media (max-width: 920px) {
    .footer {
      grid-template-columns: 10% 1fr 1fr 30%;
    }
  }
  
  @media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  
    .footer {
      min-height: 15vh;
      grid-template-columns: 10% 2fr 1fr 45%;
    }
  
    .social-link {
      margin-left: 8%;
    }
  
    .copyright {
      font-size: 15px;
    }
  }
  